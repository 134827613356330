.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

pre {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.item-enter {
  opacity: 0;
  background-color: yellow !important;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in, background-color 10s ease-out;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

label {
  font-weight: 600;
  /* 400 = normal, 700 = bold*/
}

div.rbt-input-multi {
  height: 100% !important
}